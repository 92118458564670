import { atom } from "@geome/recoil"
import { AppModes, PTVRequest, TruckRouteOptions } from "../../types"

export const appModeAtom = atom<AppModes>({ key: "appModeAtom", default: "search" })

export const mobileShowMapAtom = atom<boolean>({ key: "mobileShowMapAtom", default: false })

export const directionsViewAtom = atom<"map" | "itinerary">({
  key: "directionsViewAtom",
  default: "itinerary",
})

export const vehicleTypeAtom = atom<"car" | "truck">({ key: "vehicleTypeAtom", default: "truck" })

export const PTVLanguageAtom = atom<string>({ key: "PTVLanguageAtom", default: "en" })

export const PTVRequestUrlAtom = atom<string>({
  key: "PTVRequestUrlAtom",
  default: "",
})

export const currentPTVRequestBodyAtom = atom<PTVRequest | null>({
  key: "currentPTVRequestBodyAtom",
  default: null,
})

export const truckRouteOptionsAtom = atom<TruckRouteOptions>({
  key: "truckRouteOptionsAtom",
  default: {
    emissions_class: "",
    hazard_class: "",
    number_of_axles: 0,
    weight: 1,
    length: 1,
    height: 1,
    width: 1,
  },
})

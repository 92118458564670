import React, { ReactElement } from "react"
import { DirectionsRenderer } from "@geome/react-components-next/lib/components/map/google/directionsRenderer"
import { useRecoilValue } from "@geome/recoil"
import { appModeAtom } from "../../recoil/atoms"
import { currentPTVResponseSelector } from "../../recoil/selectors"

export const DirectionsRoute = (): ReactElement | null => {
  const appMode = useRecoilValue(appModeAtom)
  const ptvResults = useRecoilValue(currentPTVResponseSelector)

  if (appMode !== "directions" || ptvResults === null) return null
  // TODO: once the types match more remove the any
  return <DirectionsRenderer directions={ptvResults as any} />
}

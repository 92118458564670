import React, { ReactElement, useState } from "react"
import { useBEM, useI18n } from "@geome/react-next"

export type CopyLinkButtonProps = {
  id: string
}

export const CopyLinkButton = ({ id }: CopyLinkButtonProps): ReactElement => {
  const { translate } = useI18n()
  const [justClicked, setJustClicked] = useState<boolean>(false)
  const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout> | null>(null)

  const handleClick = (): void => {
    const urlToCopy = `${window.location.protocol}//${window.location.host}/#/resources/locations/${id}`
    navigator.clipboard.writeText(urlToCopy)

    setJustClicked(true)
    if (timeoutId) clearTimeout(timeoutId)
    const newTimeoutId = setTimeout(() => setJustClicked(false), 3000)
    setTimeoutId(newTimeoutId)
  }

  const className = useBEM({
    block: "info-window",
    element: "link-button",
    modifiers: {
      "just-clicked": justClicked,
    },
  })

  return (
    <button className={className} onClick={handleClick}>
      {justClicked ? translate("info_window.link_copied") : translate("info_window.copy_link")}
    </button>
  )
}
